import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { map } from 'rxjs';

import { UserService } from '../users/user.service';

/**
 * If no user logged in, user is redirected to log in screen
 */
export const authGuard: CanActivateFn = () => {
  const userService = inject(UserService);
  const router = inject(Router);

  return userService.getProfile().pipe(
    map((res) => {
      if (!res) {
        return router.createUrlTree(['auth']);
      } else {
        return true;
      }
    }),
  );
};
