import { Routes } from '@angular/router';

import { authGuard } from './core/guards/auth.guard';
import { loggedInGuard } from './core/guards/logged-in.guard';

export enum AppRoutes {
  auth = 'auth',
  home = 'home',
  about = 'about',
  permissions = 'permissions',
  routes = 'routes',
}

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: AppRoutes.auth,
    canActivate: [loggedInGuard],
    loadChildren: () =>
      import('./features/auth/auth.routes').then((m) => m.routes),
  },
  {
    path: AppRoutes.home,
    canActivate: [authGuard],
    loadChildren: () =>
      import('./features/home/home.routes').then((m) => m.routes),
  },
  {
    path: AppRoutes.about,
    canActivate: [authGuard],
    loadChildren: () =>
      import('./features/about/about.routes').then((m) => m.routes),
  },
  {
    path: AppRoutes.permissions,
    canActivate: [authGuard],
    loadChildren: () =>
      import('./features/permissions/permissions.routes').then((m) => m.routes),
  },
  {
    path: AppRoutes.routes,
    canActivate: [authGuard],
    loadChildren: () =>
      import('./features/route-detail/route-detail.routes').then(
        (m) => m.routes,
      ),
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
