export class NotificationHelper {
  static getNotificationPermissions() {
    try {
      return Notification.permission;
    } catch {
      return undefined;
    }
  }

  static requestPermission() {
    try {
      if (Notification.permission === 'granted') {
        return Promise.resolve(Notification.permission);
      }

      return Notification.requestPermission()
        .then((permission) => {
          if (permission === 'granted') {
            console.log('notification permissions granted');
          } else if (permission === 'denied') {
            console.log('notification permissions denied');
          }
          return permission;
        })
        .catch(() => {
          console.log('notification permissions: unable to get permissions');
          return undefined;
        });
    } catch (e) {
      return Promise.resolve(undefined);
    }
  }
}
