import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { map } from 'rxjs';

import { UserService } from '../users/user.service';

/**
 * If user is logged in, he is navigated to home
 */
export const loggedInGuard: CanActivateFn = () => {
  const userService = inject(UserService);
  const router = inject(Router);

  return userService.getProfile().pipe(
    map((user) => {
      if (user) {
        return router.createUrlTree(['/']);
      } else {
        return true;
      }
    }),
  );
};
